import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Btn from "../components/button"
import BtnCircle from "../components/button-circle"
import ShareButton from "../components/share-button"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  const pageTitle = data.markdownRemark.frontmatter.title
  const pageIntroduction = data.markdownRemark.frontmatter.introduction
  const pageQuote = data.markdownRemark.frontmatter.quote
  const quoteImage = getImage(data.markdownRemark.frontmatter.quoteImage)

  if (posts.length === 0) {
    return (
      <Layout location={location} title={pageTitle}>
        <SEO title="Strona główna" />
        <p>Wystąpił bląd - brak obrazów w galerii.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={pageTitle}>
      <div className="section">
        <SEO title="Strona główna" />
        <div className="columns">
          <div className="column content-container has-background-dark is-6">
            <div className="is-flex is-justify-content-flex-end is-align-content-center mb-2 social-share-container">
              <ShareButton location={location} />
            </div>
            <section className="section has-text-justified has-text-weight-light is-size-5 pb-4">
              <p className="has-dropcap"> {pageIntroduction} </p>
            </section>
            <BtnCircle path={"/zyciorys"} description={"CZYTAJ DALEJ"} />

            <div className="mt-6 quote-image-container">
              <GatsbyImage
                objectFit="fullWidth"
                objectPosition="center center"
                image={quoteImage}
                alt={`Obraz - tytuł here`}
                style={{ maxHeight: "400px" }}
              />
            </div>
            <section className="section quote-section">
              <p className="has-text-centered has-text-weight-light is-size-4 is-family-secondary is-italic">
                „{pageQuote}”
              </p>
            </section>
          </div>
          <div className="column is-1"></div>
          <div className="column is-5">
            <Btn
              path={"/galeria"}
              description={"ZAPRASZAMY DO GALERII OBRAZÓW"}
            ></Btn>
            <p className="subtitle is-6 is-family-secondary pt-6 mb-4 has-text-weight-light">
              NOWE OBRAZY W GALERII
            </p>
            <ol style={{ listStyle: `none` }}>
              {posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug

                const featuredImage = getImage(post.frontmatter.featuredImage)

                return (
                  <li className="mb-5" key={post.fields.slug}>
                    <Link to={post.fields.slug} itemProp="url">
                      <section>
                        <GatsbyImage
                          objectFit="cover"
                          objectPosition="center center"
                          image={featuredImage}
                          alt={`Obraz - tytuł here`}
                          style={{}}
                        />
                      </section>
                      <div className="columns mb-0 is-mobile">
                        <div className="column has-text-centered">
                          <span
                            className="subtitle is-size-7 is-uppercase has-text-weight-light is-gold-link has-letter-spacing-1"
                            itemProp="headline"
                          >
                            {title}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "/(homepage)/" }) {
      frontmatter {
        title
        introduction
        quote
        quoteImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      html
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(blog)/" }
        frontmatter: { inGallery: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          date(formatString: "YYYY-MM-DD")
          title
          description
          inGallery
        }
      }
    }
  }
`
